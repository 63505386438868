.bx--checkbox-label::before {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  width: 24px;
  height: 24px;
}

.bx--checkbox-label-text {
  margin: 4px 0 0 16px;
  color: var(--trilogy-black);
}

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state="true"]::before,
.bx--checkbox-label[data-contained-checkbox-state="mixed"]::before {
  border-width: 1px;
  border-color: var(--trilogy-denim-light);
  background-color: transparent;
  box-shadow: var(--denim-shadow);
}

.bx--checkbox-label::after {
  border-bottom: 1px solid var(--trilogy-denim);
  border-left: 1px solid var(--trilogy-denim);
  margin: -1px 0px 0px 4px;
  align-self: center;
  height: 6px;
  width: 10px;
}
