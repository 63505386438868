.bx--modal-container {
  color: var(--trilogy-black);
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.25);
  background-color: var(--bg-white);
  .bx--modal-content {
    margin-bottom: 1rem;
  }
  .bx--btn {
    height: 46px;
    padding: 0;
    align-self: center;
    align-items: center;
    justify-content: center;
    min-width: 125px;
  }
  .bx--btn--primary {
    margin: 0 24px 24px 0;
  }
  .bx--btn--secondary {
    margin: 0 24px 24px;
    color: var(--trilogy-denim);
    border-color: var(--trilogy-denim);
    background-color: var(--bg-white);
    transition: all 0.1s ease-in-out;
    &:hover {
      // not sure why the box-shadow is not working in the modal
      // changing text/border instead for now
      color: var(--trilogy-denim-dark);
      border-color: var(--trilogy-denim-dark);
      background-color: var(--bg-white);
      box-shadow: var(--denim-shadow);
      transition: all 0.1s ease-in-out;
    }
    &:focus {
      background-color: var(--bg-white);
      color: var(--trilogy-denim);
      border-color: var(--trilogy-denim);
      background-color: var(--bg-white);
    }
  }
  // .bx--btn-set {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}

// .bx--modal-container--xs {
// }

.bx--modal-header__heading {
  color: var(--trilogy-black);
  font-weight: 700;
  font-size: 16px;
  margin: 0 0 0 8px;
}

.modal-content {
  display: flex;
  margin: 0 0 0 8px;
  color: var(--trilogy-black);
  overflow: hidden;
  height: 78px;
  line-height: 1.5;
}

.copy-icon {
  margin: 0 16px 0 0;
  padding: 11px 11px 5px;
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
  background-color: var(--hover-gray);
  color: var(--trilogy-black);
  border: 1px solid var(--hover-gray);
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: var(--hover-gray);
    color: var(--trilogy-black);
    border: 1px solid var(--trilogy-denim-light);
  }
}

.case-id {
  color: var(--trilogy-black);
}

.copied-text {
  margin-bottom: -2px;
  font-size: 12px;
  color: var(--trilogy-denim);
}

.modal-subtitle {
  // font-weight: 700;
  font-size: 12px;
}
