#header-container {
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  max-height: 100px;
  background-color: var(--trilogy-denim-dark);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--bg-white);
  top: 0;
  width: 100%;
  font-weight: 700;
  padding: 2vw 7vw;
  h1 {
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    padding-right: 2vw;
    cursor: default;
    color: var(--bg-white);
  }
}

#logout-link {
  color: white;
  cursor: pointer;
}

.sharp-logo {
  padding: 5px;
  height: 90px;
  background: var(--bg-white);
  color: var(--bg-white);
  border-radius: 6px;
  display: inline-flex;
}

.logo-text {
  font-size: 20px;
  display: inline-flex;
  flex-grow: 1;
  padding-left: 20px;
}
