.toggle-container {
  display: flex;
  width: 100%;
  margin: 0;
  > div:first-child {
    margin: 0 0 8px 0;
  }
}

.toggle {
  margin: 0 0 0 16px;
}

.bx--toggle-input__label {
  color: var(--trilogy-black);
}

// switch container
.bx--toggle__switch {
  top: 8px;
  display: flex;
  justify-content: center;
  border: solid 1px var(--hover-gray);
  border-radius: 6px;
  width: 64px;
  height: 46px;
  margin: 0 !important;
}

// switch background/position
.bx--toggle__switch:before {
  background-color: var(--disabled-gray-dark);
  top: 14px;
  height: 16px;
  width: 32px;
}

// circle position
.bx--toggle__switch::after {
  left: 16px;
  top: 15px;
  height: 14px;
  width: 14px;
}

// checked background color
.bx--toggle-input:checked
  + .bx--toggle-input__label
  > .bx--toggle__switch::before {
  background: var(--trilogy-denim-light);
}

// circle checked animation
.bx--toggle-input:checked
  + .bx--toggle-input__label
  > .bx--toggle__switch::after {
  transform: translateX(1rem);
}

.first-child-padding {
  padding-top: 24px;
}

.sibling-fields {
  margin-bottom: 36px;
  .fields-container-mobile {
    display: block;
    > div:nth-child(1) {
      padding-right: 80px;
      margin-bottom: 24px;
    }
    > div:nth-child(2) {
      margin-left: 0;
      padding-right: 80px;
      margin-top: -24px;
      
    }
    > div:nth-child(3) {
      float: right;
      margin-top: -134px;
    }
  }
}