.progress {
  position: -webkit-sticky;
  position: sticky;
  top: 128px;
  padding: 16px 24px;
  background-color: var(--bg-white);
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.25);
  text-align: left;
  margin-bottom: 32px;
  h1 {
      font-size: 14px;
      font-weight: bold;
      color: var(--trilogy-black);
      padding-bottom: 28px;
  }
  .progress-header-mobile {
    padding-bottom: 16px;
  }
  .bx--progress-line {
    margin-left: 15px;
  }
  svg {
    background-color: (var(--bg-white));
  }
}

.progress-tablet {
  .bx--progress {
    padding: 14px 0 12px;
  }
  .bx--progress-label {
    font-size: 14px;
  }
  svg {
    margin-right: 6px;
  }
  .bx--progress-line {
    margin-left: 0;
  }
  // .bx--progress-line {
  //   margin: 17px 0 0 0;
  // }
  // .bx--progress-label {
  //   z-index: 100;
  //   background-color: var(--bg-white);
  // }
  // .bx--progress-step-button .bx--progress-step-button--unclickable {
  //   margin: auto;
  // }
}

.progress-mobile {
  .bx--progress {
    padding: 6px;
  }
  box-shadow: none;
  border: var(--border-standard);
  svg {
    margin: auto;
    background-color: (var(--bg-white));
  }
  .bx--progress-line {
    margin: 7px 0 0 0;
  }
}

.bx--progress-step {
  min-width: 0px;
}

.bx--progress-line {
  width: 100%;
}

.bx--progress--vertical {
  padding: 0;
}

.bx--progress-step--incomplete .bx--progress-line {
  background-color: #e0e0e0;
  // background-color: var(--disabled-gray-light);
}

.bx--progress-step--complete .bx--progress-line {
  background-color: var(--trilogy-denim)
}

.bx--progress--vertical .bx--progress-step,
.bx--progress--vertical .bx--progress-step-button {
  padding-top: 0.75em;
}
