.error-container {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 400px;
  color: var(--trilogy-denim-dark);
  font-size: 18px;
  font-weight: 700;
  cursor: default;
  .error-icon {
    color: var(--error-red);
  }
}

.error-content {
  margin-left: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  .error-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--trilogy-black);
    margin: 24px 0;
    max-width: 400px;
  }
  .bx--btn {
    padding: 0;
    justify-content: center;
  }
}
