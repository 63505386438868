:root {
  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;

  --trilogy-denim-light: #03a9e0;
  --trilogy-denim: #3682b5;
  --trilogy-denim-dark: #26638b;
  --trilogy-denim-extra-dark: #1a4b6b;
  --trilogy-black: #494949;

  --error-red: #cb2525;
  --hover-gray: #f0f2f3;
  --hover-gray-light: #fafafa;

  --disabled-gray-light: #f0f0f0;
  --disabled-gray-medium: #c4c4c4;
  --disabled-gray-dark: #949494;

  --denim-shadow: 0px 0px 6px 0px #03a9e040;
  --error-shadow: 0px 0px 6px 0px #cb252540;
  --border-standard: 1px solid var(--disabled-gray-medium);
}
