.button-group-container {
  display: flex;
  justify-content: flex-start;
}

.first-button-container {
  margin: 0 32px 0 0;
  width: 50%;
}

.second-button-container {
  width: 50%;
  .bx--btn {
    width: 100%;
  }
}
