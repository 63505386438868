.bx--radio-button__appearance {
  border-color: var(--disabled-gray-medium);
  width: 24px;
  height: 24px;
  &:hover {
    border-color: var(--trilogy-denim-light);
  }
}

// checked
.bx--radio-button:checked
  + .bx--radio-button__label
  .bx--radio-button__appearance {
  border-color: var(--trilogy-denim-light);
  box-shadow: var(--denim-shadow);
  &::before {
    background: var(--trilogy-denim);
    width: 12px;
    height: 12px;
  }
}

.bx--radio-button-wrapper .bx--radio-button__label {
  align-items: center;
}
