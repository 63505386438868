.bx--fieldset {
  .bx--label {
    margin-bottom: 24px;
  }
}

.bx--list-box__field {
  border: 1px solid var(--disabled-gray-medium);
  border-radius: 6px;
  background: var(--bg-white);
  // display: none;
  height: 46px;
}

.bx--dropdown {
  border: none;
}

// dropdown menu  ** Selected Item background color still needs to be fixed **
.bx--list-box__menu {
  background-color: var(--bg-white);
  border-radius: 6px;
}

.bx--list-box__menu-item__option {
  width: 100%;
  margin-left: 0px;
  padding-left: 16px;
}

.bx--list-box__menu-item {
  background-color: var(--bg-white);
}

//dropdown menu hovered item
.bx--list-box__menu-item:hover {
  background-color: var(--hover-gray);
}

.bx--list-box__menu-item__option:hover {
  background-color: var(--hover-gray);
}

// error for dropdown
.bx--dropdown--invalid {
  background-color: var(--bg-white);
  border-radius: 8px;
  border-color: var(--error-red);
  padding: 2px 2px 3px;
  height: 50px;
  max-height: 50px;
}

.bx--list-box--expanded .bx--list-box__menu {
  .bx--list-box__menu-item {
    height: 46px;
  }
  .bx--list-box__menu-item__option {
    white-space: normal;
    height: 46px;
    display: flex;
    align-items: center;
  }
}