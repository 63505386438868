// All

.bx--btn {
  font-weight: 700;
  border-radius: 6px;
  transition: all 0.1s ease-in-out;
  min-width: 164px;
  max-width: 186px;
  width: 40%;
  height: 46px;
  &:hover {
    .bx--btn__icon {
      margin: 0;
    }
  }
}

.bx--btn:hover:disabled {
  .bx--btn__icon {
    margin: 0 10%;
  }
}

.bx--btn__icon {
  margin: 0 10%;
  transition: all 0.1s ease-in-out;
}

// Primary

.bx--btn--primary {
  background-color: var(--trilogy-denim);
  &:hover {
    background-color: var(--trilogy-denim-dark);
  }
  &:disabled {
    background-color: var(--disabled-gray-medium);
    color: var(--bg-white);
  }
}

.bx--btn--primary:disabled:hover {
  background-color: var(--disabled-gray-medium);
  color: var(--bg-white);
}

// Tertiary

.bx--btn--tertiary {
  color: var(--trilogy-denim);
  border-color: var(--trilogy-denim);

  .bx--btn__icon {
    margin: 0px;
  }
  &:hover {
    .bx--btn__icon {
      margin-right: 22px;
    }
    color: var(--trilogy-denim);
    border-color: var(--trilogy-denim);
    background-color: var(--bg-white);
    box-shadow: var(--denim-shadow);
  }
  &:focus {
    background-color: var(--bg-white);
    color: var(--trilogy-denim);
    border-color: var(--trilogy-denim);
    background-color: var(--bg-white);
  }
}

// Secondary

.bx--btn--secondary {
  width: 149px;
  float: right;
  margin-right: 19px;
  height: 40px;
  padding-right: 0;
  background-color: var(--hover-gray);
  color: var(--trilogy-black);
  &:hover {
    background-color: var(--hover-gray);
    color: var(--trilogy-black);
    border-color: var(--trilogy-denim-light);
  }
  &:disabled {
    background-color: white;
    color: var(--disabled-gray-medium);
  }
  .bx--btn__icon {
    margin: 0;
  }
}
