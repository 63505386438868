@import "antd/dist/antd.css";
@import "~carbon-components/scss/globals/scss/styles.scss";
@import "./variables/colors.scss";

body {
  margin: 0 !important;
  font-family: Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}
