// overwriting default carbon component color tokens
$interactive-04: var(--trilogy-denim);
$focus: var(--trilogy-denim);
$ui-03: var(--trilogy-denim);
$focus: var(--trilogy-denim);

@import "carbon-components/scss/globals/scss/styles.scss";

// development overlay to show form data
#data-overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.app {
  height: auto;
  min-height: 100vh;
  background-color: var(--bg-gray);
}

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 64px;
  padding-bottom: 32px;
}

.main-tablet {
  margin: 32px 0;
}

.grid {
  // border: 1px solid red;
  width: 90%;
}
// .row {
// border: 1px solid blue;
// }
// .col {
// border: 1px solid yellow;
// }

#form-container {
  padding: 48px 24px;
  background-color: var(--bg-white);
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.25);
}

.tab-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

$left-padding: percentage(3/12);
$left-padding-title: percentage(11/50);

.tab-title {
  color: var(--trilogy-denim-dark);
  font-size: 18px;
  font-weight: 700;
  padding: 0 $left-padding-title;
  display: flex;
}

.form-button-container {
  display: flex;
  padding: 0 $left-padding-title;
}

.form-section {
  width: 80%;
  border: var(--border-standard);
  border-radius: 6px;
  margin: 24px 0;
  padding: 16px 12% 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.form-section-horizontal {
  flex-direction: row;
  > div:first-child {
    margin-right: 16px;
  }
}

.form-sections-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

// input sizes
$field-top-margin: 24px;

@mixin all-sizes {
  margin-top: $field-top-margin;
  min-width: 100px;
}

.small {
  width: 24%;
  @include all-sizes();
}

.medium {
  width: 42%;
  @include all-sizes();
}
.large {
  width: 64%;
  @include all-sizes();
}

.extra-large {
  width: 86%;
  @include all-sizes();
}

.extra-extra-large {
  width: 100%;
  @include all-sizes();
}

// style for all carbon component labels
.bx--label {
  color: var(--trilogy-black);
  display: flex;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
}

// custom label used for date picker
.input-label {
  color: var(--trilogy-black);
  display: flex;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
  overflow: hidden;
  min-height: 16px;
  padding-top: 2px;
}

.sub-section-title {
  color: var(--trilogy-denim-extra-dark);
  font-weight: 700;
  font-size: 16px;
  margin: 24px 0 24px;
}

.row-horizontal {
  display: flex;
  width: 100%;
  > div:first-child {
    margin-right: 16px;
  }
}

.back-to-review-button {
  padding-left: 32px;
}


// mobile styles
.app-mobile {
  background-color: var(--bg-white);
  #form-container {
    padding: 0;
    box-shadow: none;
  }
  .form-section {
    width: 100%;
    padding: 16px 8% 40px;
  }
  .tab-title {
    padding: 0 4%;
  }
  .page-notification {
    padding: 0 4%;
  }
  .form-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
    .bx--btn {
      width: 100%;
      max-width: none;
    }
  }
  .back-to-review-button {
    width: 100%;
    padding: 0;
  }
  .confirm-button {
    width: 100%;
    max-width: none;
    .second-button-container {
      width: 100%
    }
    .bx--btn {
      width: 100%;
      display: flex;
      justify-content: center;
      .bx--btn__icon {
        margin: 0 20%;
      }
    }
  }
  .button-group-container {
    width: 100%;
    max-width: none;
    .bx--btn {
      min-width: 20%;
      width: 100%
    }
  }
  .begin-entry-button {
    .bx--btn {
      display: flex;
      justify-content: center;
      .bx--btn__icon {
        margin: 0 30%;
      }
    }
  }
  .extra-extra-large, .extra-large, .large, .medium, .small {
    width: 100%;
  }
  .row-horizontal {
    flex-direction: column;
    .extra-extra-large, .extra-large, .large, .medium, .small {
      margin: 0;
    }
  }
  
  // dropdown mobile
  .bx--list-box--expanded .bx--list-box__menu {
    left: -10vw;
    width: 90vw;
    .bx--list-box__menu-item__option {
      text-align: center;
      justify-content: center;
    }
  }
}