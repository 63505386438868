ul {
  list-style-type: circle;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 13px;
}

.page-notification p {
  font-size: 13px;
  line-height: 14px;
}

.page-notification {
  color: var(--trilogy-black);
  padding: 0 12% 0 22%;
}

.back-to-review-button {
  .bx--btn {
    padding: 0;
    justify-content: center;
  }
}