// .bx--date-picker__input {
//   border: 1px solid var(--disabled-gray-medium);
//   background-color: var(--bg-white);
//   border-radius: 6px;
//   height: 46px;
//   width: 100%;
// }

// .bx--date-picker-input__wrapper {
//   margin-bottom: 24px;
// }

// .bx--date-picker-container {
// }

// .bx--date-picker.bx--date-picker--simple .bx--date-picker__input,
// .bx--date-picker.bx--date-picker--simple .bx--label {
//   width: 100%;
// }

.ant-picker-large.ant-picker {
  border: 1px solid var(--disabled-gray-medium);
  background-color: var(--bg-white);
  border-radius: 6px;
  height: 46px;
  width: 100%;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid var(--disabled-gray-medium);
  background-color: var(--bg-white);
  border-radius: 6px;
  height: 46px !important;
  width: 100%;
  align-items: center;
}

// .ant-select-selection-item {
//   height: 46px;
//   max-height: 46px;
// }
