.review-section-user {
  padding: 24px 112px;
  color: var(--trilogy-black);
}

.accordion {
  width: 80%;
  margin: 24px;
}

.name {
  font-weight: 700;
  padding: 0 5% 0 25%;
}

.field-container {
  display: flex;
  :nth-child(2) {
    width: 50%;
    margin-bottom: 16px;
  }
}

.field-name {
  font-weight: 700;
  width: 53%;
  padding: 0 0 16px 22.5%;
}

.bx--accordion__content {
  color: var(--trilogy-black);
  padding-right: 20px;
}

.bx--accordion__title {
  color: var(--trilogy-denim-dark);
  font-size: 18px;
  font-weight: 700;
}

.bx--accordion__heading:hover::before {
  background-color: var(--hover-gray-light);
}

.review-section-title {
  display: flex;
  justify-content: space-between;
}

.review-section-title {
  display: flex;
  justify-content: space-between;
  > :first-child {
    margin-left: 16px;
  }
}

.review-section-mobile {
  .accordion {
    width: 100%;
    padding: 0;
  }
  .field-name {
    width: 53%;
    padding: 0 0 16px 5%;
  }
  .bx--btn {
    width: 46px;
    min-width: 46px;
  }
}
