.bx--text-area {
  border: 1px solid var(--disabled-gray-medium);
  background-color: var(--bg-white);
  border-radius: 6px;
}

.bx--text-area-wrapper {
  margin-bottom: 24px;
}

.bx--text-area {
  height: 68px;
}
