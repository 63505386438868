.bx--number input[type="number"] {
  border: 1px solid var(--disabled-gray-medium);
  background-color: var(--bg-white);
  border-radius: 6px;
  height: 46px;
  min-width: 0rem;
}

// .bx--number__input-wrapper {
//   margin-top: 8px;
// }
