.bx--text-input {
  border: 1px solid var(--disabled-gray-medium);
  background-color: var(--bg-white);
  border-radius: 6px;
}

.bx--text-input-wrapper {
  margin-bottom: 24px;
}

.bx--text-input {
  height: 46px;
}
